import { useRef, useState } from 'react'

const thematics = {
  '1': 'Adult',
  '2': 'Dating',
  '3': 'Finance - Crypto',
  '4': 'Finance - Trading',
  '5': 'Gambling - Betting',
  '6': 'Gambling - Casino',
  '7': 'Games',
  '8': 'Live Streaming - Sports',
  '9': 'Nutra',
  '10': 'Shopping',
  '11': 'Streaming - Video/TV',
  '12': 'Sweepstakes',
  '13': 'VPN',
}

const getHeadSection = (
  partenrId,
  productId,
  thematicsId,
  adNetworkParam,
  offerParam,
  placementParam,
  formatParam,
  opt1,
  opt2,
  opt3,
) => {
  const product = productId ? `params['${productId}'] || null` : null

  const adNetwork = adNetworkParam
    ? `params['${adNetworkParam}'] || null`
    : null
  const offerId = offerParam ? `params['${offerParam}'] || null` : null
  const placement = placementParam
    ? `params['${placementParam}'] || null`
    : null
  const format = formatParam ? `params['${formatParam}'] || null` : null

  const opt_1 = opt1 ? `params['${opt1}'] || null` : null
  const opt_2 = opt2 ? `params['${opt2}'] || null` : null
  const opt_3 = opt3 ? `params['${opt3}'] || null` : null

  return `<script type="text/javascript" data-adel="aseng" src="//rigill.com/script/at.js"></script>
<script type="text/javascript">
function getQueryParams() {
    var params = {}, tokens, re = /[?&]?([^=]+)=([^&]*)/g, queryString = window.location.search;
    queryString = queryString.split('+').join(' ');
    while (tokens = re.exec(queryString)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }
    return params;
}

params = getQueryParams();
UFET.start({
    partnerId: "${partenrId}",
    productId: ${product},
    thematics: "${thematicsId}",
    adNetwork: ${adNetwork},
    offerId: ${offerId},
    placement: ${placement},
    format: ${format},
    opt: {
        opt1: ${opt_1},
        opt2: ${opt_2},
        opt3: ${opt_3},
    }
});
</script>`
}

const getBodySection = (partnerId, thematicsId) => {
  return `<noscript>
  <img src="//quesid.com/ut/aft.php?nojs=1&pid=${partnerId}&ths=${thematicsId}" alt="no script" />
</noscript>
<div style="display:none;visibility:hidden;position:relative;left:-1000px;top:-1000px;">
  <a href="https://quesid.com/ut/aft.php?dbv=1&pid=${partnerId}&ths=${thematicsId}">here</a>
  <a href="https://quesid.com/al/visit.php?al=11,13,1" ></a>
</div>`
}

function App() {
  const partnerIdInput = useRef()
  const productIdInput = useRef()
  const thematicsIdInput = useRef()
  const adNetworkParamInput = useRef()
  const offerParamInput = useRef()
  const placementParamInput = useRef()
  const formatParamInput = useRef()
  const optParam1 = useRef()
  const optParam2 = useRef()
  const optParam3 = useRef()

  const [isSent, setIsSent] = useState(false)
  const [headSection, setHeadSection] = useState()
  const [bodySection, setBodySection] = useState()

  const selectAll = (e) => {
    let range = new Range()
    range.setStart(e.target, 0)
    range.setEnd(e.target, 1)
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(range)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const partenrId = partnerIdInput.current.value
    const productId = productIdInput.current.value
    const thematicsId = thematicsIdInput.current.value
    const adNetworkParam = adNetworkParamInput.current.value || null
    const offerParam = offerParamInput.current.value || null
    const placementParam = placementParamInput.current.value || null
    const formatParam = formatParamInput.current.value || null
    const opt1 = optParam1.current.value || null
    const opt2 = optParam2.current.value || null
    const opt3 = optParam3.current.value || null

    setHeadSection(
      getHeadSection(
        partenrId,
        productId,
        thematicsId,
        adNetworkParam,
        offerParam,
        placementParam,
        formatParam,
        opt1,
        opt2,
        opt3,
      ),
    )
    setBodySection(getBodySection(partenrId, thematicsId))

    setIsSent(true)
  }

  return (
    <div className="container">
      {!isSent && (
        <div>
          <h1>Generate your Seetrue.io script</h1>

          <form onSubmit={onSubmit}>
            <div className="row">
              <h4>Mandatory Params</h4>
              <div className="input-group">
                <label htmlFor="partner_id">Partner Id: </label>
                <input
                  id="partner_id"
                  type="text"
                  ref={partnerIdInput}
                  required
                />
              </div>

              <div className="input-group">
                <label htmlFor="thematics_id">Thematics: </label>
                <select id="thematics_id" ref={thematicsIdInput} required>
                  <option value="">Please select your thematics...</option>
                  {Object.entries(thematics).map((t) => {
                    return (
                      <option key={`k-${t[0]}`} value={t[0]}>
                        {t[1]}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>

            <div className="row">
              <h4>Optional Params</h4>
              <div className="input-group">
                <label htmlFor="product_id">Product Id: </label>
                <input id="product_id" type="text" ref={productIdInput} />
              </div>

              <div className="input-group">
                <label htmlFor="adnetwork_param">AdNetwork Param: </label>
                <input
                  id="adnetwork_param"
                  type="text"
                  ref={adNetworkParamInput}
                />
              </div>

              <div className="input-group">
                <label htmlFor="offer_param">OfferId Param: </label>
                <input id="offer_param" type="text" ref={offerParamInput} />
              </div>

              <div className="input-group">
                <label htmlFor="placement_param">PlacementId Param: </label>
                <input
                  id="placement_param"
                  type="text"
                  ref={placementParamInput}
                />
              </div>

              <div className="input-group">
                <label htmlFor="format_param">Format Param: </label>
                <input id="format_param" type="text" ref={formatParamInput} />
              </div>

              <div className="input-group">
                <label htmlFor="opt1_param">Optional Param 1: </label>
                <input id="opt1_param" type="text" ref={optParam1} />
              </div>

              <div className="input-group">
                <label htmlFor="opt2_param">Optional Param 2: </label>
                <input id="opt2_param" type="text" ref={optParam2} />
              </div>

              <div className="input-group">
                <label htmlFor="opt3_param">Optional Param 3: </label>
                <input id="opt3_param" type="text" ref={optParam3} />
              </div>
            </div>

            <input type="submit" value="Generate Script" />
          </form>
        </div>
      )}
      {isSent && (
        <div>
          <h1>Your Seetrue.io script was generated!</h1>

          <h4>Put this snippet in your HTML HEAD section</h4>
          <pre>
            <code onClick={selectAll}>{headSection}</code>
          </pre>

          <h4>Put this snippet in your HTML BODY section</h4>
          <pre>
            <code onClick={selectAll}>{bodySection}</code>
          </pre>

          <button onClick={() => window.location.reload()}>
            Generate New Script
          </button>
        </div>
      )}
    </div>
  )
}

export default App
